<template>
  <div class="dashboard-content">
    <div class="row">
       <div class="col-lg-12 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
            <h6 class="mb-2" style="text-transform:uppercase">
             Realtor Ad - Purchase/Edit - Your Zip Code
            </h6>
            <hr>
            <div class="row">
                <div class="col-md-8 table-responsive">
                <form action="">
                        <div class="form-check">
                            <input :disabled="self_purchased == 'true' || other_resltor_purchased == 'true' " :checked="self_purchased == 'false' && other_resltor_purchased == 'false'" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" >
                            <label class="form-check-label " for="flexRadioDefault1" style="font-size:17px">
                            $5 Weekly, Purchase Your ZIP Code
                            </label>
                        </div>
                        <div class="form-check">
                            <input :checked="self_purchased === 'true'" :disabled="self_purchased == 'false'" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" >
                            <label class="form-check-label" for="flexRadioDefault2" style="font-size:17px">
                                You Have Already Purchased Your ZIP Code - {{ self_purchased }}
                            </label>
                        </div>
                        <div class="form-check">
                            <input :disabled="other_resltor_purchased == 'false'" :checked="other_resltor_purchased == 'true'"  class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3">
                            <label class="form-check-label" for="flexRadioDefault3" style="font-size:17px">
                                A Realtor has Already Purchased Your ZIP Code
                            </label>
                        </div>
                        
                </form>
                </div>
            </div>
            <div class="row">
                <div class=col-md-6>
                    <router-link to="/select-ad" class="btn btn-success mt-3"><i class="fas fa-arrow-left"></i> Back</router-link>
                </div>
                <div class=col-md-6>
                    <router-link to="/create-realtor-ad" class="btn btn-primary mt-3" style="float:right"> Next <i class="fas fa-arrow-right"></i></router-link>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="copyrights">
          © 2021 Thriggle. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {axios} from '../../../services/http-common';
export default {
  data(){
    return {
      self_purchased:'',
      other_resltor_purchased:'',

    }
  },
  mounted(){
    this.self_purchased = localStorage.getItem('self_realtor');
    this.other_resltor_purchased = localStorage.getItem('other_realtor_buy_ad');
  }
}
</script>
<style scoped>
.dashboard-list-box ul li{
    padding: 13px 30px !important;
}
.uppercase{
    text-transform: uppercase !important;
}
</style>
